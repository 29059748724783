import React from "react";
import theme from "theme";
import { Theme } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				CycleCare Pro
			</title>
			<meta name={"description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:title"} content={"CycleCare Pro"} />
			<meta property={"og:description"} content={"Ваша надійна майстерня з ремонту велосипедів"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66795e8d3f24780021f825e0/images/2-3.jpg?v=2024-06-24T13:31:01.473Z"} />
		</Helmet>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66028e06867c86001f1d8b21"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});